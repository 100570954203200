import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"
import BloodGroupLink from "../../components/BloodGroupLink"

import { theme } from "../../styles/theme"

import compatibilityData from "../../data/compatibilityData"

const CompatibilityIndex = ({ location }) => {
  const bloodGroups = Object.keys(compatibilityData)

  const bloodGroupList = bloodGroups.map(g => (
    <BloodGroupLink
      key={g}
      group={g}
      destination="/compatibility/component-choice"
    />
  ))

  return (
    <Layout>
      <SEO title="Blood Components Compatibility" />

      <CustomNavTitle
        title="Blood Components Compatibility"
        destination="/"
        location={location}
      />

      <article className="mt-gap-2" css={theme.components.row}>
        <header css={styles.header}>
          <p className="mb-gap-2 max-w-content">
            <strong>To check for compatible blood components please select the patient's
            blood group. <br />
            Remember to check{" "}
            <Link
              css={styles.link}
              to="/positive-patient-identification"
              state={{ backOverride: "/compatibility/" }}
            >
              positive patient identification
            </Link>
            .</strong>
          </p>

          <p className="mb-gap-2 max-w-content" css={styles.red}>
            <strong>
              Caution: for haematopoietic stem cell transplant patients please seek advice from
              the transfusion laboratory staff.
            </strong>
          </p>
        </header>

        <p css={styles.instruction}>Please select the patient's blood group</p>

        <ul css={styles.list}>{bloodGroupList}</ul>
      </article>
    </Layout>
  )
}

const styles = {
  header: css({
    color: theme.colours.black,
    marginBottom: theme.gapDouble,
    borderBottom: `2px solid ${theme.colours.lightGrey}`,
  }),
  link: css({
    color: theme.colours.red,
    textDecoration: "underline",
    "&:hover, &:focus": {
      textDecoration: "none",
    },
  }),
  red: css({
    color: theme.colours.red,
  }),
  instruction: css({
    fontWeight: "bold",
    color: theme.colours.red,
    fontSize: theme.fzH3,
    margin: `${theme.gap} 0`,
  }),
  list: css({
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.gap,
    marginBottom: theme.gap,
    "&>*": {
      marginBottom: theme.gap,
      width: "50%",
      padding: "0 0.5rem",
      "@media (min-width: 620px)": {
        width: "25%",
      },
      "@media (min-width: 1020px)": {
        width: "12.5%",
      },
    },
  }),
}

export default CompatibilityIndex
