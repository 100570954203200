import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { theme } from "../styles/theme"

const BloodGroupLink = ({ destination, group }) => {
  return (
    <li css={styles.container}>
      <Link to={destination} state={{ group: group }} css={styles.link}>
        {group}
      </Link>
    </li>
  )
}

const styles = {
  link: css({
    backgroundColor: theme.colours.lightGrey,
    paddingHorizontal: 10,
    paddingVertical: 40,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    borderRadius: theme.borderRadius,
    height: 0,
    minHeight: 140,
    color: theme.colours.black,
    fontSize: theme.fzH1,
    fontWeight: "bold",
    "&:hover, &:focus": {
      backgroundColor: "#e0e0e0",
    },
  }),
}

export default BloodGroupLink
